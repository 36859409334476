<template>
  <div v-if="!pageLoaded" style="width: 100%">
    <loader/>
  </div>
  <div v-else style="width: 100%;">


    <div v-if="allPortfolioCount === 0 && portfolios.length === 0 && !hasPassivePortfolios && activeStatus === 1 && !showAddPortfolio && !showLoader" class="no-area">
      <img class="img" src="https://gcdn.bionluk.com/site/cicons/il-my-portfolio.png">
      <p class="title">Bionluk Portfolyonu <span>Oluştur</span></p>
      <p class="text">Sevgili <span>{{this.user.firstname ? this.user.firstname : this.user.username}}</span>, neler yapabildiğini alıcılara göstermek için etkileyici bir portfolyo oluştur. Unutma oluşturacağın portfolyo senin imzan!</p>
      <button @click="activateAddPortfolio()" class="cuper-red-button" style="margin-top: 40px;">Portfolyonu Oluştur</button>
    </div>

    <div v-else-if="(allPortfolioCount || portfolios.length || activeStatus !== 1 || hasPassivePortfolios || showLoader) && !showAddPortfolio" class="gig-list">

      <div style=" position: relative;  margin-top: 30px;">
        <button style="position: absolute; right: 0; width:185px; font-size: 16px; letter-spacing: -0.3px; font-weight: 600; min-width: 120px!important; padding-right: 25px; padding-left:25px;"
                class="cuper-red-button"
                @click="activateAddPortfolio()">
          Yeni Portfolyo Ekle
        </button>
      </div>
      <p class="page-title"><span>Portfolyom</span></p>
      <p class="page-sub-title">Tüm portfolyonu buradan takip edebilir, yönetebilir ve yeni portfolyolar ekleyebilirsin.</p>


      <div style="margin-top: 30px; display: flex; justify-content: space-between">
        <select v-model="activeStatus" name="status" class="cuper-drop-slim" style=" width: 310px; background-position-x: 285px;">
          <option :value="1">Yayındakileri göster</option>
          <option :value="0">Yayında olmayanları göster</option>
        </select>
        <div style="display: flex; align-items: center">
          <div @click="clickedShareButton"  class="share-area">
            <social-sharing :url="'https://bionluk.com/portfolyo/'+user.username" inline-template>
              <div class="user-socialmedia-icons">

                <network
                  network="facebook" style="cursor: pointer;">
                  <div data-balloon="Facebook'ta paylaş"
                       data-balloon-pos="up">
                    <img style="width: 26px; height: 26px; border-radius: 50%" src="https://gcdn.bionluk.com/site/cicons/png/facebook.png"/>
                  </div>

                </network>

                <network network="twitter" style="cursor: pointer; margin-left: 20px;">
                  <div data-balloon="Twitter'da paylaş"
                       data-balloon-pos="up">
                    <img style="width: 26px; height: 26px; border-radius: 50%" src="https://gcdn.bionluk.com/site/cicons/png/twitter.png"/>
                  </div>
                </network>

                <network network="linkedin" style="cursor: pointer; margin-left: 20px;">
                  <div data-balloon="Linkedin'de paylaş"
                       data-balloon-pos="up">
                    <img style="width: 26px; height: 26px; border-radius: 50%"src="https://gcdn.bionluk.com/site/cicons/png/linkedin.png"/>
                  </div>
                </network>
              </div>
            </social-sharing>
            <div
              data-balloon="Direkt linki kopyala"
              data-balloon-pos="up"
              @click="copyToClipboard()" style="margin-left: 20px;">
              <img  class="share-icon" style="width: 26px; height: 26px; border-radius: 50%; cursor: pointer" src="https://gcdn.bionluk.com/site/cicons/png/link.png"/>
            </div>

          </div>
        </div>
      </div>

      <div v-if="!showLoader && !portfolios.length">
        <p style="margin-top: 30px;color: #b2bcc8">Bu bölümde içerik yok.</p>
      </div>

      <div style="margin-top: 50px; margin-left: -29px">
        <div class="portfolio-body" v-if="!showLoader">
          <div v-for="(portfolio, pi) in portfolios" :key="portfolio.uuid" class="portfolio-box">
            <div class="head-section">
              <p class="title">{{portfolio.name}}</p>
              <p class="sub-title" v-if="portfolio.rating_user.id"><router-link :to="'/'+portfolio.rating_user.username">{{portfolio.rating_user.username}}</router-link> için yapıldı.</p>
              <p v-else class="sub-title">{{portfolio.category_sub_name}}</p>
            </div>
            <div class="portfolio-img" @click="showPortfolioDetailModal(portfolio)">
              <div v-if="portfolio.file_type === 'audio'" class="sound-icon-container">
                <img src="https://gcdn.bionluk.com/site/cicons/ic-sound.svg" onload="SVGInject(this)" class="sound-icon">
              </div>
              <div v-if="portfolio.file_type === 'video'" class="sound-icon-container">
                <img src="https://gcdn.bionluk.com/site/cicons/ic-video.svg" onload="SVGInject(this)" class="sound-icon">
              </div>
              <img :src="portfolio.image_url_small">
            </div>
            <div :class="'statusArea ' + portfolio.statusTextClass">
              {{portfolio.statusText}}
            </div>
            <div class="foot-section">
              <div style="display: flex">
                <img src="https://gcdn.bionluk.com/site/cicons/ic-like-fill.svg" onload="SVGInject(this)" class="like-icon"/>
                <span>{{portfolio.fav_count}}</span>
                <img src="https://gcdn.bionluk.com/site/cicons/ic-messages.svg" onload="SVGInject(this)" class="like-icon"/>
                <span>{{portfolio.comment_count}}</span>
              </div>
              <div style="display: flex">
                <p
                  data-balloon="Sil"
                  data-balloon-pos="up"
                  @click="changeStatus(portfolio,-1)">
                  <img src="https://gcdn.bionluk.com/site/cicons/ic-trash.svg" onload="SVGInject(this)" class="trash-icon"/>
                </p>
                <p v-if="portfolio.status === 1"
                   style="margin-left: 20px;"
                   data-balloon="Yayından kaldır"
                   data-balloon-pos="up"
                   @click="changeStatus(portfolio,0)">
                  <img src="https://gcdn.bionluk.com/site/cicons/ic-eye-close.svg" onload="SVGInject(this)" class="edit-icon">
                </p>
                <p v-if="portfolio.status === 0"
                   style="margin-left: 20px;"
                   data-balloon="Tekrar yayına al"
                   data-balloon-pos="up"
                   @click="changeStatus(portfolio,1)">
                  <img src="https://gcdn.bionluk.com/site/cicons/ic-eye-open.svg" onload="SVGInject(this)" class="edit-icon">
                </p>
                <p
                  style="margin-left: 20px;"
                  data-balloon="Düzenle"
                  data-balloon-pos="up"
                  @click="showPortfolioDetailModal(portfolio, 'editPortfolio')" >
                  <img title="" src="https://gcdn.bionluk.com/site/cicons/ic-edit-penci.svg" onload="SVGInject(this)" class="edit-icon">
                </p>
              </div>
            </div>
          </div>
        </div>
        <div style="display: flex;justify-content: center; margin-top: 30px;">
          <a v-if="seemorediv && !seemoreLoader" @click="listPortfolios(activeStatus, 0, true)" style="cursor: pointer;font-weight: 500; font-size:16px;color: #2d3640;">
            Daha fazla +
          </a>
        </div>
        <loader v-if="seemoreLoader"></loader>
        <loader v-if="showLoader"></loader>
      </div>


    </div>


    <div v-if="showAddPortfolio" class="add_portfolio_div" style="">
      <div style="padding: 0px 30px 0px; float: right;">
        <button @click="deActivateAddPortfolio()" class="editButton" style="width: 80px;">
          <img src="https://gcdn.bionluk.com/site/icon/aws/ic_chevron_left_white.svg"
               style="height: 8px; margin-right: 5px;">
          Geri dön
        </button>
      </div>

      <p class="page-header">Yeni Portfolyo Ekle</p>
      <div class="content-white" style="padding: 30px 0 30px 30px;">

        <div style="margin-bottom: 0px;">
          <div style="display: inline-block">
            <label style="font-weight: 400!important;  font-size: 17px; margin-bottom: 10px; text-align: left;">Ana Kategori</label>
            <select v-model="selectedCategory" class="super-drop">
              <option disabled :value="null">Kategori Seç</option>
              <option v-for="category in categories" v-if="!category.parent_id" :value="category.id">{{ category.name }}</option>
            </select>
          </div>
          <div style="display: inline-block; margin-left: 30px;">
            <label style="font-weight: 400!important;  font-size: 17px; margin-bottom: 10px; text-align: left;">Alt Kategori</label>
            <select :disabled="!selectedCategory" v-model="selectedSubCategory" class="super-drop">
              <option disabled :value="null">Kategori Seç</option>
              <option v-for="subCategory in categories" v-if="selectedCategory && (selectedCategory === subCategory.parent_id)"
                      :value="subCategory.id">{{ subCategory.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="hiddendiv20"></div>

        <label style="font-weight: 400!important;  font-size: 17px; margin-bottom: 10px; text-align: left;">Başlık *</label>
        <input maxlength="50" v-model="title" placeholder="Örneğin: Tasarladığım logolar" style="width: 250px;"/>

        <div class="hiddendiv20"></div>

        <label style="font-weight: 400!important;  font-size: 17px; margin-bottom: 10px; text-align: left;">Açıklama</label>
        <textarea maxlength="500" v-model="description" placeholder="İşinle ilgili kısa bir açıklama" style="width: 420px; height: 100px;"></textarea>


        <div class="hiddendiv20"></div>
        <!--cover-->
        <div v-show="openCover">
          <label class="portfolio-cover-header">Kapak Görseli</label>
          <div class="cover-container-passive" v-if="!uploadedCover.image_url && !dragAndDropArea">
            <label for="filesPortfolioCover" class="cover-label-container">
              <progress v-if="responseStatusToUploadCover === 'started'" :value='percentCompleted.portfolio[timeStamp]' max="100"></progress>
              <div style="padding-top: 36px;">
                <img v-if="!responseStatusToUploadCover" :src="imageURL">
                <div v-if="!responseStatusToUploadCover" class="cover-header">
                  Kapak Görseli
                </div>
              </div>
              <input id="filesPortfolioCover" style="visibility:hidden;" type="file" @change="onFileChangeToUploadCover" v-if="responseStatusToUploadCover !== 'started'">
            </label>
          </div>
          <div id="portfolio-drag-drop-cover"
               v-if="!uploadedCover.image_url && dragAndDropArea"
               draggable="true"
               @drop="dropHandler($event, 'cover')"
               @dragover="dragOverHandler($event)">
            {{dragDropTextCover}}
          </div>
          <div class="cover-container-active" v-if="uploadedCover.image_url">
            <img @click="removeCoverUpload()"
                 class="cover-remove-button"
                 :src="$root.CDN_SITE + 'icon/ic_delete.svg'">
            <img :style="{width: `${uploadedCover.width}px`, height: `${uploadedCover.height}px`}" :src="uploadedCover.image_url"
                 @load="coverImageOnload($event, uploadedCover)">
            <loader v-if="uploadedCover.loader"></loader>
          </div>

          <p style="font-size: 12px; margin-top: 10px; width: 400px;"> - Kapak görseli yüklenmediği takdirde otomatik olarak oluşturulacaktır.<br><br> - Portfolyo kapak görseli için önerilen boyut 16:9,<br> - Sadece png ve jpg formatında görseller yüklenebilir. <br> - Tavsiye edilen maksimum
            genişlik 1400px'dir.<br> - Maksimum dosya boyutu 2mb.</p>
          <div class="hiddendiv20"></div>
        </div>


        <label class="portfolio-media-header">Ana İçerik</label>

        <div class="media-container-passive" v-if="!uploadedMedia.image_url && !dragAndDropArea">
          <label for="filesPortfolio3" class="media-label-container">
            <progress v-if="uploadResponseStatus === 'started'" :value='percentCompleted.portfolio[timeStamp]' max="100"></progress>
            <div style="padding-top: 90px;">
              <img v-if="!uploadResponseStatus" :src="imageURL">
              <div class="image-header" v-if="!uploadResponseStatus">
                Yeni İçerik Ekle
              </div>
            </div>
            <input id="filesPortfolio3" style="visibility:hidden;" type="file" @change="onFileChange" v-if="uploadResponseStatus !== 'started'">
          </label>
        </div>
        <div id="portfolio-drag-drop-media"
             v-if="!uploadedMedia.image_url && dragAndDropArea"
             draggable="true"
             @drop="dropHandler($event, 'media')"
             @dragover="dragOverHandler($event)">
          {{dragDropTextMedia}}
        </div>

        <div class="video-container" v-if="uploadedMedia.fileType === 'video'">
          <img src="https://gcdn.bionluk.com/site/icon/icon_check_green.svg">
          <div class="video-separator"></div>
          <p class="warning-text"> Yükleme Başarılı. Ekleme işlemine devam edebilirsin. Yüklediğin video işlendikten sonra otomatik olarak iş ilanı sayfanda gözükecek. </p>
        </div>

        <div class="media-container-active" v-if="uploadedMedia.image_url">
          <img @click="removeMediaUpload()" class="media-remove-button" :src="$root.CDN_SITE + 'icon/ic_delete.svg'"/>
          <img class="media-image" :src="uploadedMedia.image_url" @load="imageOnload($event, uploadedMedia)" v-if="uploadedMedia.fileType === 'image' || uploadedMedia.fileType === 'video'"/>
          <div class="audio-container" v-if="uploadedMedia.fileType === 'audio'">
            <img @click="removeMediaUpload()" class="media-remove-button" :src="$root.CDN_SITE + 'icon/ic_delete.svg'"/>
            <img src="https://gcdn.bionluk.com/site/icon/ic_play_circle_fill.svg" class="media-play-icon" @click="activateIframe()" v-if="!activatedIframe"/>
            <img class="media-image" :src="uploadedMedia.image_url" @load="imageOnload($event, uploadedMedia)" v-if="!activatedIframe"/>
            <audio
              autoplay
              controls
              v-if="activatedIframe"
              :src="uploadedMedia.videoUrl"
            >
            </audio>
          </div>
          <loader v-if="uploadedMedia.loader"></loader>
        </div>
        <div class="hiddendiv10"></div>
        <p style="font-size: 12px; width: 370px"> - Sadece resim, video veya ses dosyası ekleyebilirsin.</p>
        <p style="font-size: 12px; width: 370px; margin-top: 5px;"> - Geçerli dosya formatları: <strong>.jpg, .png, .mp4 ve .mp3</strong></p>
        <p style="font-size: 12px; width: 370px; margin-top: 5px;"> - Resim dosyaları için maksimum boyut 2mb, video ve ses dosyaları için maksimum boyut 300 mb ve 90 saniyedir.</p>


        <button @click="savePortfolio()" :disabled="disableToSave" class="super-button" style="margin-top: 40px;">Kaydet
        </button>
      </div>

    </div>
    <div class="hiddendiv"></div>
    <div class="hiddendiv"></div>
    <div class="hiddendiv"></div>
  </div>
</template>

<script>
  import draggable from 'vuedraggable'
  import isEmpty from 'lodash/isEmpty'

  export default {
    name: "src-pages-body-workstation-portfolio-v1",
    components: {
      draggable
    },
    data() {
      return {

        allPortfolioCount:0,
        hasPassivePortfolios:false,
        disabledButtons:false,
        selectedCategory: null,
        selectedSubCategory: null,
        showAddPortfolio: false,
        pageLoaded: false,
        title: null,
        description: null,
        imageURL: `${this.$root.CDN_SITE}icon/icon_add_pink.svg`,
        externalID: null,
        timeStamp: null,
        uploadResponseStatus: null,
        activeProgress: null,
        portfolios: [],
        offset: 0,
        showLoader: null,
        activeStatus: 1,
        uploadedMedia: {},
        totalFiles: 0,
        uploadedFileCount: 0,
        uploadedCover: {},
        responseStatusToUploadCover: null,
        mediaFile: null,
        openCover: false,
        limit: 18,
        seemorediv: false,
        seemoreLoader: false,

        activatedIframe: false,
        dragAndDropArea: false,
        dragDropText: 'Dosyaları bu alana sürükle.',
        // dragdrop
        dragDropTextCover: '',
        dragDropTextMedia: '',

      }
    },

    methods: {



      changeStatus(portfolio, status) {
        if(this.disabledButtons) return false
        if (status === -1) {
          if (confirm("Portfolyondan bu içeriği silmek istediğinden emin misin? Eğer silersen ilgili iş ilalarından da bu içeriği çıkartacağız") === true){
            this.disabledButtons = true;
            this.api.seller.deletePortfolio(portfolio.uuid, this.$Progress)
              .then(({data}) => {
                let result = data;
                if (result.success) {
                  this.$toasted.global.infoToast({description: result.message});
                  this.EventBus.$emit('updatePortfolio', {uuid:portfolio.uuid, what:'delete', type:null});
                  this.disabledButtons = false;
                } else {
                  this.$toasted.global.errorToast({description: result.message});
                  this.disabledButtons = false;
                }
              })
              .catch(err => {
                this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
                this.disabledButtons = false;
              });
          }
        } else {
          this.disabledButtons = true;
          this.api.seller.changePortfolioStatus(portfolio.uuid, status, this.$Progress)
            .then(({data}) => {
              let result = data;
              if (result.success) {
                const portfolioIndex = this.portfolios.findIndex(({ uuid }) => uuid === portfolio.uuid)
                this.portfolios.splice(portfolioIndex, 1);
                this.$toasted.global.infoToast({description: result.message});
                this.disabledButtons = false;
              } else {
                this.$toasted.global.errorToast({description: result.message});
                this.disabledButtons = false;
              }
            })
            .catch(err => {
              this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
              this.disabledButtons = false;
            });
        }
      },

      copyToClipboard(){
        const el = document.createElement('textarea');
        el.value = 'https://bionluk.com/portfolyo/'+this.user.username;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        this.$toasted.global.infoToast({description: 'Portfolyo linkin kopyalandı.'});
      },

      clickedShareButton(){
        this.Helper.trackEvents.customEvent("clicked", {version:'v1',page:this.$store.state.componentMap.page,what:'portfolio share buttons'});
      },


      removeCoverUpload() {
        this.uploadedCover = {};
      },

      removeMediaUpload() {
        this.uploadedMedia = {};
      },

      imageOnload(event, upload) {
        upload.width = 400;
        upload.height = 226;
        upload.loader = false;
        this.openCover = true;

        if (this.mediaFile && upload.loader === false && isEmpty(this.uploadedCover)) {
          this.$store.commit(this.types.ACTIVE_MODAL, {
            modalType: this.Modals.CROPPER,
            info: {file: this.mediaFile, canvasPixel: {width: 1400, height: 788}, divider: 2.5, viewMode: 1, fileType: "onFileChangeToUploadCover"}
          });
          document.getElementById("filesPortfolioCover").value = "";
        }
      },

      coverImageOnload(event, upload) {
        upload.width = 200;
        upload.height = 113;
        upload.loader = false;
      },

      savePortfolio() {
        let portfolio = {
          name: this.title,
          description: this.description,
          category_id: this.selectedCategory,
          category_sub_id: this.selectedSubCategory,
          upload_id: this.uploadedMedia.upload_id,
          cover_id: this.uploadedCover.upload_id,
          type: "upload"
        };

        this.activeProgress = true;
        this.api.seller.createPortfolioV2(portfolio, this.$Progress)
          .then(({data}) => {
            let result = data;
            this.activeProgress = null;
            if (result.success) {
              this.portfolios.push(result.data);
              this.showAddPortfolio = null;
              this.openCover = false;
              this.selectedCategory = null;
              this.selectedSubCategory = null;
              this.description = null;
              this.title = null;
              this.externalID = null;
              this.uploadedCover = {};
              this.uploadedMedia = {};
              this.$toasted.global.infoToast({description: result.message});
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      },

      onFileChangeToUploadCover(e) {
        let files = e.target.files || e.dataTransfer.files;

        this.api.general.checkCoverIsImage(files[0].type)
          .then(({data}) => {
            let result = data;
            this.activeProgress = null;
            if (result.success) {
              this.$store.commit(this.types.ACTIVE_MODAL, {
                modalType: this.Modals.CROPPER,
                info: {file: files[0], canvasPixel: {width: 1400, height: 788}, divider: 2.5, viewMode: 1, fileType: "onFileChangeToUploadCover"}
              });
              e.target.value = '';

            } else {
              e.target.value = '';
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(err => {
            e.target.value = '';
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });

      },

      onFileChange(e) {
        this.mediaFile = null;
        let files = e.target.files || e.dataTransfer.files;

        if (files[0].type.split("/")[0] === "image") {
          this.mediaFile = files[0];
        }
        this.uploadResponseStatus = "started";
        this.timeStamp = +new Date();
        this.iterateUploadFileToGoogle(files[0], e);
      },

      iterateUploadFileToGoogle(files, e) {
        this.uploadFileToGoogle(files, this.Constants.UPLOAD_TYPES.PORTFOLIO, this.timeStamp)
          .then(result => {
            this.uploadedMedia = {
              fileType: result.file_type,
              upload_id: result.externalID,
              image_url: result.url,
              videoUrl: result.video_url,
              width: 0,
              height: 0,
              loader: true
            };
            this.uploadResponseStatus = null;
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: err ? err.message : this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            this.uploadResponseStatus = null;
          });
      },

      showPortfolioDetailModal(portfolio, modalType) {
        if(this.disabledButtons) return false
        if (modalType === "editPortfolio") {
          this.$router.push('/panel/portfolyo/duzenle/'+portfolio.uuid)
          //this.$store.commit(this.types.ACTIVE_MODAL, {modalType: modalType, info: {data: portfolio}});
        } else {
          this.$store.state.showCase.data = portfolio;
          this.$store.state.showCase.activeStatus = this.activeStatus;
          this.$store.commit(this.types.ACTIVE_MODAL, {modalType: 'portfolio', info: {uuid: portfolio.uuid, allItems:this.portfolios}});
        }
      },

      listPortfolios(status, offset, seemore) {
        this.seemorediv = false;
        if (!seemore) {
          this.offset = offset;
          this.showLoader = true;
        } else {
          this.seemoreLoader = true;
        }
        this.activeStatus = status;
        this.api.seller.listPortfolios(null, status, this.limit, this.offset)
          .then(({data}) => {
            this.showLoader = false;
            let result = data;

            if (result.success) {
              if (seemore) {
                this.seemoreLoader = false;
                this.portfolios = this.portfolios.concat(result.data.portfolios);
              } else {
                this.portfolios = result.data.portfolios;
              }
              this.offset += this.limit;
              this.seemorediv = result.data.seemorediv;
              if(status === 1 && result.data.has_passive_portfolios ){
                this.hasPassivePortfolios = true;
              }

              this.allPortfolioCount = result.data.all_portfolio_count;
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      },

      activateAddPortfolio() {
        this.$router.push('/panel/portfolyo/yeni');
        //this.showAddPortfolio = true;
      },

      deActivateAddPortfolio() {
        this.showAddPortfolio = false;
        this.openCover = false;
        this.selectedCategory = null;
        this.selectedSubCategory = null;
        this.title = null;
        this.description = null;
        this.imageURL = `${this.$root.CDN_SITE}icon/icon_add_mavi.svg`;
        this.externalID = null;
        this.timeStamp = null;
        this.api.general.updateUploadStatus(this.uploadedMedia.upload_id, this.Constants.UPLOAD_STATUSES.DELETE);
        this.uploadedMedia = {};
        this.totalFiles = 0;
        this.uploadedFileCount = 0;
        this.uploadedCover = {};
        this.responseStatusToUploadCover = null;
      },

      eventBusOnFileChangeToUploadCover(payload) {
        this.responseStatusToUploadCover = "started";
        this.timeStamp = +new Date();

        this.uploadFileToGoogle(payload.file, this.Constants.UPLOAD_TYPES.PORTFOLIO, this.timeStamp)
          .then(result => {
            this.uploadedCover = {
              upload_id: result.externalID,
              image_url: result.url,
              width: 0,
              height: 0,
              loader: true
            };
            this.responseStatusToUploadCover = null;
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: err ? err.message : this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            this.uploadResponseStatus = null;
          });
      },

      deactivateIframeLoader() {
        this.activatedIframe = false;
      },

      activateIframe() {
        this.activatedIframe = true;
      },

      dragStartEvent(event) {
        this.dragAndDropArea = true;
        if (event.target.id === "portfolio-drag-drop-cover") {
          this.dragDropTextCover = 'Dosyaları bu alana bırakın.';
        }

        else if (event.target.id === "portfolio-drag-drop-media") {
          this.dragDropTextMedia = 'Dosyaları bu alana bırakın.';
        }

      },

      dragLeaveEvent(event) {

        if (event.originalEvent && (!event.originalEvent.clientX && !event.originalEvent.clientY)) {
          //outside body / window
          this.dragAndDropArea = false;
        } else if ((!event.clientX && !event.clientY) || (!event.pageX && !event.pageY)) {
          this.dragAndDropArea = false;
        }

        else if (event.target.id === "portfolio-drag-drop-cover") {
          this.dragDropTextCover = 'Dosyaları bu alana sürükle.';
        }

        else if (event.target.id === "portfolio-drag-drop-media") {
          this.dragDropTextMedia = 'Dosyaları bu alana sürükle.';
        }
      },

      dropHandler(event, type) {


        this.EventBus.$emit('dragAndDropFile', null);

        event.preventDefault();
        event.stopPropagation();

        if (type === 'cover') {
          this.onFileChangeToUploadCover(event);
        }
        else if (type === 'media') {
          this.onFileChange(event);
        }
        this.dragAndDropArea = false;
      },

      dragOverHandler(event) {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';  // See the section on the DataTransfer object.
      },
    },

    computed: {
      disableToSave() {
        return !this.title || !this.selectedCategory || !this.selectedSubCategory  || this.uploadResponseStatus || !this.uploadedMedia.upload_id;
      }
    },

    watch: {
      selectedCategory() {
        this.selectedSubCategory = null;
      },
      activeStatus: function (newVal) {
        if(this.disabledButtons) return false;
        this.listPortfolios(newVal, 0)
      },
      "$store.state.windowScrollTop": function (newValue, oldValue) {
        if(oldValue && newValue){
          this.$store.state.portfolioScrollTopPosition = newValue;
        }
      }
    },

    created() {
      window.addEventListener('dragleave', this.dragLeaveEvent, false);
      window.addEventListener('dragenter', this.dragStartEvent, false);


      this.EventBus.$on('cropperImageFile', payload => {
        if (payload.fileType === "onFileChangeToUploadCover") {
          this.eventBusOnFileChangeToUploadCover(payload);
        }
      });


      this.EventBus.$on('updatePortfolio', payload => {

        let uuid = payload.uuid;
        let what = payload.what;
        let type = payload.type;

        if(what==='comment' || what==='fav_count'){
          this.portfolios.forEach(function (portfolio) {
            if (portfolio.uuid === uuid) {
              if(what==='comment'){
                if(type === 'plus') portfolio.comment_count += 1;
                else  portfolio.comment_count -= 1;
              }


              if(what==='fav_count'){
                if(type === 'plus') portfolio.fav_count += 1;
                else  portfolio.fav_count -= 1;
              }

            }
          });
        }

        if(what==='delete'){
          this.portfolios = this.portfolios.filter(function (portfolio) {
            return portfolio.uuid !== uuid;
          });
        }

        if(what==='update'){
          this.portfolios = this.portfolios.filter((portfolio) => {
            return portfolio.status === this.type;
          });
        }

      });




      this.api.seller.listPortfolios(null, this.activeStatus, this.limit, this.offset)
        .then(({data}) => {
          let result = data;
          if (result.success) {
            this.pageLoaded = true;
            this.portfolios = result.data.portfolios;
            this.offset += this.limit;
            if(result.data.has_passive_portfolios ){
              this.hasPassivePortfolios = true;
            }
            this.allPortfolioCount = result.data.all_portfolio_count;
            this.seemorediv = result.data.seemorediv;
            this.$nextTick(() => {
              window.scrollTo(0,this.$store.state.portfolioScrollTopPosition);
            });
          } else {
            this.$toasted.global.errorToast({description: result.message});
          }
        })
        .catch(err => {
          this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
        });
    },

    beforeDestroy() {
      window.removeEventListener('dragleave', this.dragLeaveEvent, false);
      window.removeEventListener('dragenter', this.dragStartEvent, false);
      this.EventBus.$off('updatePortfolio');
      this.EventBus.$off("cropperImageFile");
    }
  }
</script>

<style scoped lang="scss">
  .portfolio-body{
    display: flex;
    flex-wrap: wrap;

    .portfolio-box{
      position: relative;
      margin-left: 26px;
      margin-bottom: 30px;
      width: 280px;
      height: 274px;
      border-radius: 10px;
      box-shadow: 0 0 10px 0 rgba(45, 54, 64, 0.05);
      border: solid 1px #eaedf2;
      background-color: #ffffff;

      .head-section{
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 20px;
        height: 66px;
        .title{
          font-size: 14px;
          font-weight: 600;
          color: #2d3640;
        }
        .sub-title{
          margin-top: 5px;
          font-size: 12px;
          font-weight: 300;
          color: #8b95a1;
          a{
            font-size: 12px;
            font-weight: 600;
            color: #fd4056;
            &:hover{
              text-decoration: underline;
              color: #fd4056!important;
            }
          }
        }
      }

      .portfolio-img{

        position: relative;
        cursor: pointer;
        height: 158px;
        img{
          width: 100%;
        }
      }

      .foot-section{

        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: 20px;
        margin-right: 20px;
        height: 48px;

        font-size: 16px;
        font-weight: 600;
        color: #8b95a1;

        span{
          font-size: 16px;
          font-weight: 600;
          color: #8b95a1;
          padding-left: 5px;
          padding-right: 15px;
          line-height: 0.9;

        }
        .like-icon /deep/ {
          cursor: pointer;
          width: 16px;
          height: 16px;
          path {
            fill: #bfc8d2!important;
          }
        }

        .trash-icon /deep/ {
          cursor: pointer;
          width: 20px;
          height: 20px;
          path {
            fill: #bfc8d2!important;
            &:hover{
              fill: #2d3640!important;
            }
          }
        }

        .trash-icon:hover{
          path {
            fill: #2d3640!important;
          }
        }

        .edit-icon /deep/ {
          cursor: pointer;
          width: 20px;
          height: 20px;
          path {
            fill: #bfc8d2!important;
            &:hover{
              fill: #2d3640!important;
            }
          }
        }
        .edit-icon:hover{
          path {
            fill: #2d3640!important;
          }
        }

      }
    }
  }

  .share-area{

    display: flex;


    .user-socialmedia-icons {
      display: flex;
      flex-direction: row;
      height: 26px;
    }

    .text{
      font-size: 18px;
      font-weight: normal;
      line-height: 1.33;
      color: #8b95a1;
    }


  }

  .gig-list{

    margin-left: 57px;
    width: 895px;
    .page-title{
      font-size: 34px;
      font-weight: 400;
      color: #2d3640;
      span{
        font-weight: 600;
      }
    }

    .page-sub-title{
      font-size: 18px;
      margin-top: 10px;
      line-height: 1.44;
      letter-spacing: normal;
      color: #6a7685;
      max-width: 693px;

    }



  }

  .no-area{

    margin: 60px auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    .img{
      width: 335px;
      height: 182px;
    }

    .title{
      margin-top: 50px;
      margin-bottom: 16px;
      font-size: 34px;
      font-weight: normal;
      text-align: center;
      color: #2d3640;
      span{
        font-weight: bold;
      }
    }
    .text{
      max-width: 543px;
      font-size: 18px;
      line-height: 1.44;
      text-align: center;
      color: #5e6b79;
      span{
        font-weight: bold;
        color: #2d3640;
      }
    }
  }




  .kare {

    height: 200px;
    width: 230px;
    background-color: #ffffff;

  }

  /*portfolio*/

  .portfolio-title-header {
    font-weight: 400 !important;
    font-size: 17px;
    margin-bottom: 10px;
    text-align: left;
  }

  .portfolio-cover-header {
    font-weight: 400 !important;
    font-size: 17px;
    margin-bottom: 10px;
    text-align: left;
  }

  .cover-label-container {
    cursor: pointer;
    height: 113px;
    margin: 0;
  }

  .cover-header {
    font-size: 14px;
    font-weight: 500;
    margin-top: 4px;
    color: #bfc8d2
  }

  .cover-container-passive {
    width: 200px;
    height: 113px;
    background-color: #fdfcfc;
    border: dashed 2px #ccd3d3;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .cover-container-active {
    width: 200px;
    height: 113px;
    background-color: #fdfcfc;
    border: dashed 2px #ccd3d3;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
  }

  .cover-remove-button {
    cursor: pointer;
    position: absolute;
    opacity: 1;
    top: 10px;
    right: 20px;
  }

  .portfolio-add-right-side {
    flex: 4
  }

  .portfolio-media-header {
    font-weight: 400 !important;
    font-size: 17px;
    margin-bottom: 10px;
    text-align: left;
  }

  .media-container-passive {
    width: 350px;
    height: 226px;
    background-color: #fdfcfc;
    border: dashed 2px #ccd3d3;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .media-label-container {
    cursor: pointer;
    height: 226px;
    margin: 0;
  }

  .image-header {
    margin-top: 5px;
    font-size: 17px;
    font-weight: 400;
    color: #bfc8d2;
  }

  .media-container-active {
    position: relative;
    max-width: 400px;
    max-height: 350px;
    background-color: #fdfcfc;
    border: dashed 2px #ccd3d3;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .media-remove-button {
    cursor: pointer;
    position: absolute;
    opacity: 1;
    top: 10px;
    right: 20px;
    visibility: visible;
  }

  .media-image {
    max-width: 400px;
    max-height: 350px;
  }

  .audio-container {
    position: relative;
  }

  .media-play-icon {
    height: 30px;
    width: 30px;
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);

    border-radius: 50%;
    border: solid 1px #fff;
    background-color: #fff;
  }

  .video-container {
    width: 360px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    background-color: rgb(236, 249, 245);
    border: 1px solid rgb(103, 204, 100);
    padding: 8px 10px 8px 30px;
    margin-bottom: 15px;

    .video-separator {
      height: 40px;
      width: 0;
      margin-left: 19px;
      border-left: solid 1px #e6eaea;
    }

    .warning-text {
      font-size: 13px;
      margin-left: 18px;
      color: rgb(103, 204, 100);
    }
  }

  /*dragdrop*/
  #portfolio-drag-drop-cover {
    position: relative;
    height: 115px;
    width: 202px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(white, 0.9);
    -webkit-transition: all 1s;
    transition: all 0.5s;
    font-size: 13px;
    color: rgb(230, 50, 98);
    font-weight: 500;
    border: 2px dashed rgb(230, 50, 98);
  }

  #portfolio-drag-drop-media {
    position: relative;
    height: 230px;
    width: 355px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(white, 0.9);
    -webkit-transition: all 1s;
    transition: all 0.5s;
    font-size: 15px;
    color: rgb(230, 50, 98);
    font-weight: 500;
    border: 2px dashed rgb(230, 50, 98);
  }

  .statusArea {

    z-index: 33;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 180px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 3px;
    height: 27px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;

    font-size: 14px;
    font-weight: normal;
    color: #ffffff;
  }
  .green {
    background: #6dce6a;
  }

  .gray {
    background: #8b95a1;
    box-shadow: 0 0 10px 0 #00000019;
  }

  .yellow {
    background: #f29933;
  }

  .red {
    background: #fd4056;
    box-shadow: 0 0 10px 0 #00000019;
  }

</style>
